<template>
  <div>
    <ul class="biz-breadcrumb">
      <li><router-link to="/supplier/manage">システム管理</router-link></li>
      <li><a>OPEN案内</a></li>
    </ul>
    <div class="page">
      <div class="mt-2 card biz-primary">
        <div class="card-header">
          OPENサプライヤに交換フロー
        </div>
        <div class="card-body">
          <div class="sub-title">
            <h1>BIZHIWAY会員規約</h1>
            <div class="control">
              <a href="/policy/BIZHIWAY会員規約.pdf" target="_blank">
                <font-awesome-icon class="va-m" icon="download"></font-awesome-icon>
                BIZHIWAY会員規約をダウンロード
              </a>
            </div>
          </div>
          <div class="policy-text mt-3">
            <pre style="white-space: pre-wrap ;">
  この利用規約（以下，「本規約」といいます。）は，＿＿＿＿＿（以下，「当社」といいます。）がこのウェブサイト上で提供するサービス（以下，「本サービス」といいます。）の利用条件を定めるものです。登録ユーザーの皆さま（以下，「ユーザー」といいます。）には，本規約に従って，本サービスをご利用いただきます。

  第1条（適用）
  本規約は，ユーザーと当社との間の本サービスの利用に関わる一切の関係に適用されるものとします。
  当社は本サービスに関し，本規約のほか，ご利用にあたってのルール等，各種の定め（以下，「個別規定」といいます。）をすることがあります。これら個別規定はその名称のいかんに関わらず，本規約の一部を構成するものとします。
  本規約の規定が前条の個別規定の規定と矛盾する場合には，個別規定において特段の定めなき限り，個別規定の規定が優先されるものとします。
  第2条（利用登録）
  本サービスにおいては，登録希望者が本規約に同意の上，当社の定める方法によって利用登録を申請し，当社がこれを承認することによって，利用登録が完了するものとします。
  当社は，利用登録の申請者に以下の事由があると判断した場合，利用登録の申請を承認しないことがあり，その理由については一切の開示義務を負わないものとします。
  利用登録の申請に際して虚偽の事項を届け出た場合
  本規約に違反したことがある者からの申請である場合
  その他，当社が利用登録を相当でないと判断した場合
  第3条（ユーザーIDおよびパスワードの管理）
  ユーザーは，自己の責任において，本サービスのユーザーIDおよびパスワードを適切に管理するものとします。
  ユーザーは，いかなる場合にも，ユーザーIDおよびパスワードを第三者に譲渡または貸与し，もしくは第三者と共用することはできません。当社は，ユーザーIDとパスワードの組み合わせが登録情報と一致してログインされた場合には，そのユーザーIDを登録しているユーザー自身による利用とみなします。
  ユーザーID及びパスワードが第三者によって使用されたことによって生じた損害は，当社に故意又は重大な過失がある場合を除き，当社は一切の責任を負わないものとします。
  第4条（利用料金および支払方法）
  ユーザーは，本サービスの有料部分の対価として，当社が別途定め，本ウェブサイトに表示する利用料金を，当社が指定する方法により支払うものとします。
  ユーザーが利用料金の支払を遅滞した場合には，ユーザーは年14．6％の割合による遅延損害金を支払うものとします。
  第5条（禁止事項）
  ユーザーは，本サービスの利用にあたり，以下の行為をしてはなりません。

  法令または公序良俗に違反する行為
  犯罪行為に関連する行為
  本サービスの内容等，本サービスに含まれる著作権，商標権ほか知的財産権を侵害する行為
  当社，ほかのユーザー，またはその他第三者のサーバーまたはネットワークの機能を破壊したり，妨害したりする行為
  本サービスによって得られた情報を商業的に利用する行為
  当社のサービスの運営を妨害するおそれのある行為
  不正アクセスをし，またはこれを試みる行為
  他のユーザーに関する個人情報等を収集または蓄積する行為
  不正な目的を持って本サービスを利用する行為
  本サービスの他のユーザーまたはその他の第三者に不利益，損害，不快感を与える行為
  他のユーザーに成りすます行為
  当社が許諾しない本サービス上での宣伝，広告，勧誘，または営業行為
  面識のない異性との出会いを目的とした行為
  当社のサービスに関連して，反社会的勢力に対して直接または間接に利益を供与する行為
  その他，当社が不適切と判断する行為
  第6条（本サービスの提供の停止等）
  当社は，以下のいずれかの事由があると判断した場合，ユーザーに事前に通知することなく本サービスの全部または一部の提供を停止または中断することができるものとします。
  本サービスにかかるコンピュータシステムの保守点検または更新を行う場合
  地震，落雷，火災，停電または天災などの不可抗力により，本サービスの提供が困難となった場合
  コンピュータまたは通信回線等が事故により停止した場合
  その他，当社が本サービスの提供が困難と判断した場合
  当社は，本サービスの提供の停止または中断により，ユーザーまたは第三者が被ったいかなる不利益または損害についても，一切の責任を負わないものとします。
            </pre>
          </div>
          <div class="text-center mt-4">
            <b-form-checkbox v-model="accepted">上記の内容を理解し、同意しました。</b-form-checkbox>
          </div>
          <div class="text-center mt-4">
            <j-button variant="success" size="lg" @click.prevent="toOpen">OPEN会員登録フローへ</j-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CompanyInfo from '@/model/CompanyInfo.js';
export default {
  data: function(){
    return {
      accepted: false
    }
  },
  methods: {
    toOpen(){
      if(this.accepted){
        CompanyInfo.toOpenSupplier(this.$store.state.user.companyCode).then(()=>{
          this.$router.push({name:"RegistrationWizard"});
        }).catch(reason => this.$errorMessage(undefined, {reason}));
      }else{
        this.$warningMessage("「BIZHIWAY会員規約」に合意してください。")
      }
    }
  }
}
</script>

<style scoped lang="scss">
  
  .policy-text pre{
    height: 275px;
    overflow-y: scroll;
    overflow-x: hidden;
    margin:0 auto;
    padding: 1rem;
    font-family: 'MS Mincho';
    font-size: 1rem;
    width: 80vw;
    background: #fff;
    border: 1px solid $biz-border;
  }
</style>